@import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Inria+Serif:wght@300;400;700&display=swap');

.home {
  position: relative;
  font-family: 'Barlow', sans-serif;
  width: 100vw;
  height: 100vh;
  overflow: auto;
  /* padding-bottom: 200px; */
}

.main-img {
  width: 100%;
  height: 100%; /* Set a maximum height to prevent image cutoff */
  object-fit: cover; /* Scale the image to fit within the container */
}

.gradient-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
}

.headings {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
  color: white;
}

.main-heading {
  margin: 0;
  padding: 0;
  font-size: 5vw; /* Use a responsive font size, adjust as needed */
  font-weight: 600;
}

.sub-heading {
  margin-top: 10px;
  font-size: 3.5vw; /* Use a responsive font size, adjust as needed */
  font-weight: 550;
}

.home-button {
  display: inline-block;
  padding: 8px 15px;
  border-radius: 30px; 
  background-color: #69b32e; 
  color: white;
  text-align: center;
  text-decoration: none;
  font-size: 1.5vw;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
  margin-top: 15px;
}

.home-button:hover {
  background-color: #fff; 
  color: #69b32e;
}

.shadow-box {
  background-color: white;
  padding: 20px;
  border-radius: 30px;
  box-shadow: 0 5px 12px 5px rgba(0, 0, 0, 0.4);
  z-index: 2;
  width: 80%;
  margin: 0 auto; /* Center the box */
  position: relative; /* Changed from absolute to relative */
  top: 5%; /* Reset top */
  left: 0; /* Reset left */
  transform: none; /* Remove transform */
  padding-top: 50px;
}

.shadow-box p {
  font-size: 1.5em; /* Use em for scalable font size */
  margin: 1em;
}

.shadow-box h3 {
  font-size: 2em; /* Use em for scalable font size */
}

.inner-content {
  text-align: center;
  color: black;
  z-index: 2;
}

.description-img {
  width: 100%; /* Make image responsive */
  height: auto;
  max-width: 800px; /* Max width can limit the size on larger screens */
  margin: 1em auto; /* Center the image with automatic margins */
}

/* Responsive button sizing */
.learnmore-button {
  padding: 8px 20px;
  font-size: 1.2em; /* Use em for scalable font size */
  display: inline-block; /* Allows you to set width and height */
  font-weight: bold; /* Makes the text bold */
  color: #69b32e; /* Text color */
  background-color: #fff; /* Button background color */
  border: 2px solid #69b32e; /* Adjust border color to match the picture */
  border-radius: 30px; /* Gives the button rounded corners */
  text-align: center;
  text-decoration: none;
  cursor: pointer; 
  transition: all 0.3s ease-in-out; 
}

.learnmore-button:hover {
  background-color: #69b32e; 
  color: #fff; 
}

/* Media query for smaller screens */
@media (max-width: 768px) {
  .shadow-box {
    width: 95%; /* Increase width for small screens */
    padding: 10px; /* Reduce padding */
  }

  .shadow-box p {
    font-size: 1em; /* Reduce font size */
    margin: 0.5em;
  }

  .shadow-box h3 {
    font-size: 1.5em; /* Reduce font size */
  }

  .learnmore-button {
    font-size: 1em; /* Reduce button font size */
    padding: 8px 15px;
  }

  .description-img {
    margin: 0.5em auto; /* Adjust image margin on smaller screens */
  }
}

.card-info {
  display: flex;
  justify-content: center; /* Or 'space-around', 'space-between', depending on your design */
  flex-wrap: wrap; /* Wrap cards to the next line if they don't fit */
  padding-top: 80px;
  padding-bottom: 80px;
}

.card-button:hover {
  background-color: white;
  color: #69b32e;
}

/* Add a media query for smaller screens */
@media (max-width: 768px) {
  .main-heading {
    font-size: 15vw; /* Adjust font size for smaller screens */
  }

  .sub-heading {
    font-size: 13vw; /* Adjust font size for smaller screens */
  }

  .home-button {
    font-size: 5vw;
  }
}

.navbar {
  font-size: 18px;
  font-family: 'Barlow', sans-serif;
  background: #ABBD9C;
  display: flex;
  align-items: center;
  padding-top: 0px;
  padding-bottom: 0px;
  box-shadow: 0 5px 10px 5px rgba(0, 0, 0, 0.4); 
}

.navbar-nav {
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-left: 60px;
  /* margin-right: 50px; */
  display: flex;
  align-items: center;
}

.nav-link {
  color: black !important;
  font-weight: 500;
  z-index: 2;
}

/* Custom styles for the navbar toggler */
.navbar-toggler {
  border: none; /* Remove the border if present */
  padding: 0.25rem 0.25rem; /* Reduce padding to make the button smaller */
}

/* Adjust the size of the toggler image */
.navbar-toggler img {
  height: 25px; /* Set the height of the image */
  width: auto; /* Keep the width proportional */
  display: block; /* Ensures the image is a block-level element */
}


.navbar-toggler:hover{
  color: #40820c !important;
}

.nav-link:hover{
color: #40820c !important;
}

.navbar-nav > li > a,
.navbar-toggler-icon {
    line-height: 1;
}

.rounded-button {
  display: inline-block;
  padding: 7px 25px; /* Increased padding for a larger button */
  border-radius: 25px; /* Preserved rounded corners */
  background-color: #69b32e; /* Original background color */
  color: white; /* Changed font color to white for better contrast */
  text-align: center;
  text-decoration: none;
  font-size: 20px; /* Slightly larger font size for better visibility */
  font-weight: 600; /* Bold font weight for prominence */
  border: 2px solid transparent; /* Added border for a more button-like appearance */
  cursor: pointer;
  transition: all 0.3s ease-in-out; /* Transition for all properties for smoothness */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
  z-index: 1;
}

.rounded-button:hover {
  /* background-color: #4ca803; Slightly lighter green on hover for a "highlight" effect */
  color: white; /* Keep the text color consistent on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Increase shadow on hover for a "lifting" effect */
  transform: translateY(-2px); /* Slight upward movement on hover */
}

.navbar-nav .nav-item.dropdown.show .rounded-button {
  background-color: #69b32e; /* Set the background color to the default color */
  color: black; /* Set the font color to the default color */
}

/* Add this media query to stack the links for smaller screens */
@media (max-width: 768px) {
  .navbar-nav {
    flex-direction: column;
  }
}

/********************************ABOUT US PAGE***********************************/
.split-layout {
  position: relative;
  display: flex;
  flex-direction: row; /* Make sections side by side */
  min-height: 100vh;
}

.image-section {
  flex: 1;
  position: relative;
  overflow: hidden; /* Prevent image overflow */
  max-width: 50%; /* Limit image section width to 50% */
}

.image-about {
  font-family: 'Barlow', sans-serif;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.about-us-section {
  font-family: 'Barlow', sans-serif;
  flex: 1;
  position: relative;
  padding: 100px 80px; /* Adjust padding for equal sections */
  max-width: 50%; /* Limit about section width to 50% */
  background-color: #f5f5f5;
}

.about-us-section p {
  position: relative;
  font-size: 20px;
  font-weight: 500;
  margin: 25 px;
}

.image-overlay h1 {
  font-size: 50px;
  font-weight: 600;
  margin: 0;
  padding: 0;
}

.image-overlay {
  font-family: 'Barlow', sans-serif;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  margin: 0;
  padding: 0; 
  z-index: 2;
}

.gradient-overlay-about {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
  z-index: 1;
}

.about-donate {
  font-family: 'Barlow', sans-serif;
  position: center;
  text-align: center;
}

/* Update responsive layout styles */
.responsive-layout {
  flex-direction: column; /* Stack sections vertically */
}

.responsive-image-about {
  height: 50vh; /* Set image height to 50% of viewport height */
  width: 100%; /* Allow image to take full width */
  object-fit: cover;
}

.responsive-about-us-section {
  padding: 50px 20px; /* Adjust padding for equal sections */
  width: 100%; /* Allow text to take full width */
  background-color: #f5f5f5;
}

/* Apply the full width on smaller screens */
@media (max-width: 768px) {
  .about-us-section {
    padding: 50px 50px; /* Adjust padding for smaller screens */
    max-width: 100%; /* Allow about section to take full width */
  }

  .image-section {
    max-width: 100%; /* Allow image section to take full width */
  }
}

/********************************ABOUT US PAGE***********************************/

/****************************WHY THIS PROJECT PAGE*******************************/
.page-layout {
  font-family: 'Barlow', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  min-height: 100vh;
}

.image-banner {
  font-family: 'Barlow', sans-serif;
  flex: 1;
  position: relative;
  overflow: hidden;
}

.banner-image {
  width: 100%;
  max-height: 50vh; /* 1/3 of the screen height */
  object-fit: cover;
}

.banner-text {
  font-family: 'Barlow', sans-serif;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 50px;
  font-weight: 600;
  color: white;
  margin: 0;
  padding: 0;
  text-align: center; /* Center the text horizontally */
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1; /* Ensure the text is above the overlay */
}

.gradient-banner-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0));
  z-index: 0 !important; /* Place the overlay below the text */
}

.content-section {
  font-family: 'Barlow', sans-serif;
  flex: 2;
  padding: 50px;
  background-color: white;
}

.whythis {
  font-family: 'Barlow', sans-serif;
  color: #333;
}

.whythis h1 {
  font-size: 30px;
  margin-bottom: 20px;
}

.whythis p {
  font-size: 20px;
  line-height: 1.5;
  margin-bottom: 50px;
}
/****************************WHY THIS PROJECT PAGE*******************************/


/********************************MISSION PAGE***********************************/
.mission-title {
  font-family: 'Barlow', sans-serif;
  margin-top: 50px;
  margin-bottom: 25px;
  margin-left: 90px;
  margin-right: 80px;
  font-size: 40px;
  color: green;
  font-weight: 700;
  text-align: center;
}
.mission-wrapper {
  max-width: 80vw;
  margin: 0 auto;
  font-family: 'Barlow', sans-serif;
  font-size: 20px;
  margin-bottom: 25px;
}

.mission-description {
  margin-bottom: 40px;
}

.mission-details {
  margin-bottom: 20px;
  margin-top: 10px;
}

.mission-list {
  font-family: 'Barlow', sans-serif;
  list-style: none;
  padding: 0;
  font-size: 20px;
}

.mission-list-item + .mission-list-item {
  margin-top: 1rem;
}

.mission-list-item {
  font-family: 'Barlow', sans-serif;
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap to the next line if necessary */
  align-items: center;
  gap: 1rem;
  background: #8db36f;
  padding: 0.5rem;
  border-radius: 1rem;
  width: 100%; /* Use 100% width to ensure items take full width when wrapping */
  box-shadow: 0.25rem 0.25rem 0.75rem rgb(0 0 0 / 0.1);
  margin-bottom: 40px;
}

.mission-list-item::before {
  counter-increment: list-item;
  content: counter(list-item);
  font-size: 3rem;
  font-weight: 700;
  width: 4rem;
  height: 4rem;
  background: #337003;
  flex: 0 0 auto;
  border-radius: 50%;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mission-list-item:nth-child(even) {
  background: #DAE9CE;
}

/********************************MISSION PAGE***********************************/

.donate h1 {
  color: green;
  font-weight: 700;
  text-align: center;
  font-size: 20px;
  margin-top: 50px;
}

.burial h1 {
  color: green;
  font-weight: 700;
  text-align: center;
  font-size: 20px;
  margin-top: 50px;
}

.dua h1 {
  color: green;
  font-weight: 700;
  text-align: center;
  font-size: 20px;
  margin-top: 50px;
}

.janazah h1 {
  color: green;
  font-weight: 700;
  text-align: left;
  font-size: 20px;
  margin-top: 50px;
}

.square-image {
  width: 100%;
  height: 35vh;   /* You can adjust this value to your preference */
  object-fit: cover;
}

.banner-text-bod {
  font-family: 'Barlow', sans-serif;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 50%); /* Adjust the vertical positioning */
  font-size: 50px;
  font-weight: 600;
  color: white;
  margin: 0;
  padding: 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
  width: 100%;
  position: relative;
}

.banner-text-sub {
  font-family: 'Barlow', sans-serif;
  font-size: 24px;
  color: white;
  text-align: center;
  display: block;
  z-index: 1;
  padding: 15px 0;
  margin-top: 10px; /* Adjust the margin to control the spacing */
  width: 80vw;
  font-style: italic;
}

/* Media query for screens with a maximum width of 768px (adjust as needed) */
@media (max-width: 768px) {
  .banner-text-sub {
    font-size: 4vw;
    margin-top: 50px;
  }
}

.heading-bod {
  margin-top: 50px;
  margin-bottom: 25px;
  font-size: 24px;
  font-weight: 600;
  font-family: 'Barlow', sans-serif;
}

.bod-cards {
  font-family: 'Barlow', sans-serif;
}

.carousel .slide { display: flex; justify-content: center; }

/* Contact.js */
.contact {
  margin: 50px auto;
  border: 2px solid green; /* Added green border */
  border-radius: 5px;
  padding: 30px;
  width: 80%; /* Responsive width */
  max-width: 800px; /* Max width for larger screens */
  box-sizing: border-box; /* Padding included in the width */
}

.form {
  background-color: white;
  padding: 20px;
}

.form h1 {
  margin: 10px 0 18px;
  color: green;
  font-weight: 700;
  font-size: 24px;
}

.form h4 {
  margin: 0 0 30px;
  font-size: 20px;
}

.form input,
.form textarea {
  padding: 13px;
  border-radius: 3px;
  margin-bottom: 20px;
  border: 1px solid lightgray;
  background: #fff;
  font-size: 16px;
  color: rgb(0, 0, 32);
  outline: none;
  width: calc(100% - 26px); /* Account for padding */
}

.form input:focus,
.form textarea:focus {
  border: 1px solid rgb(9, 163, 51);
}

.form textarea {
  height: auto; /* Height auto for responsiveness */
  min-height: 100px; /* Minimum height */
}

.form label {
  padding-bottom: 10px;
  color: rgb(0, 0, 32);
  font-weight: bold;
}

.form button {
  padding: 15px 20px;
  border: none;
  background-color: rgb(31, 79, 32);
  font-weight: 500;
  font-size: 18px;
  border-radius: 3px;
  color: #fff;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  width: auto; /* Width auto for responsiveness */
}

.form button:hover {
  background-color: rgb(49, 163, 51);
}

/* Media Queries for Responsive Adjustments */
@media (max-width: 768px) {
  .contact {
    width: 90%;
  }
  
  .form h1 {
    font-size: 22px;
  }
  
  .form h4 {
    font-size: 18px;
  }
  
  .form button {
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .contact {
    width: 95%;
  }
  
  .form h1 {
    font-size: 20px;
  }
  
  .form h4 {
    font-size: 16px;
  }
  
  .form input,
  .form textarea {
    font-size: 14px;
  }
  
  .form button {
    padding: 10px 15px;
    font-size: 14px;
  }
}

.form button:hover {
  background-color: rgb(49, 163, 51);
}

.donate-page-container h1{
  font-family: 'Barlow', sans-serif;
  margin-top: 50px;
  margin-bottom: 25px;
  margin-left: 90px;
  margin-right: 80px;
  font-size: 40px;
  color: green;
  font-weight: 700;
  text-align: center;
}

.donation-methods-container {
  display: flex;
  height: 1500px; /* Adjust to your preference */
}

.donorbox-container {
  width: 50%;
  padding: 50px;
  box-sizing: border-box;
  margin-left: 100px;
}

.zelle-container {
  width: 100%;
  flex-grow: 1;
  padding: 10px;
  box-sizing: border-box;
  margin-right: 50px;
}

.qna h1{
  font-family: 'Barlow', sans-serif;
  margin-top: 50px;
  margin-bottom: 50px;
  margin-left: 90px;
  margin-right: 80px;
  font-size: 40px;
  color: green;
  font-weight: 700;
  text-align: center;

}

.accordion-item {
  font-family: 'Barlow', sans-serif;
  margin-bottom: 50px;
  width: 80vw;
  font-size: 18px;
}

/* Change the default background color of the accordion button */
.accordion-item .accordion-button {
  background-color: #DAE9CE !important; /* Replace with your desired default color */
  color: black !important; /* Replace with your desired text color */
  font-size: 18px;
  font-weight: 550;
}

/* Change the background color of the focused (clicked) accordion button */
.accordion-item .accordion-button:focus {
  background-color: #69b32e !important; /* Replace with your desired active color */
}

/* Change the background color of the active accordion button */
.accordion-item .accordion-button:active {
  background-color: #69b32e !important; /* Replace with your desired active color */
}


.qna p{
  margin-top: 15px;
  margin-bottom: 30px;
  margin-left: 80px;
  margin-right: 80px;
  text-align: left;
  font-size: 20px;
}

.qna li{
  font-size: 20px;
  margin-left: 80px;
  margin-right: 80px;
  text-align: left;
  font-weight: 500;
}


.donateze h1{
  color: green;
  font-weight: 700;
  text-align: center;
  font-size: 25px;
  margin-top: 50px;
}

.donatezelle {
  padding: 0 80px;
}

.donatezelle p {
margin-top: 30px;
text-align: left;
font-size: 18px;
}

.donatezelle b {
font-size: 22px;
color: green;
}

.donatezelle ul {
margin-top: 30px;
margin-left: 0;
/* padding-left: 20px; */
list-style-position: inside;
}

.donatezelle li {
margin-bottom: 20px;
color: green;
margin-left: -30px;
}

/* Responsive Styles */
@media screen and (max-width: 768px) {
  .donation-methods-container {
      flex-direction: column; /* stack the children vertically */
      height: auto;
      align-items: center; /* center align the items if they're less than full width */
  }

  .donorbox-container,
  .zelle-container {
      width: 100%; /* take full width of the parent */
      margin-left: 0;
      margin-right: 0;
  }

  .donate-page-container h1 {
      margin-left: 30px;  /* reduce the margin for smaller screens */
      margin-right: 30px;
  }

  .donatezelle {
      padding: 0 30px;  /* reduce the padding for smaller screens */
  }
}

.pledgeForm {
  width: 100%; /* Use 100% width to use the full container width */
  max-width: 640px; /* Optional: you can set a max-width if the form is too wide on large screens */
  margin: auto; /* This will center the div if it has a max-width */
  background-color: #96ab98;
}

.googleForm {
  width: 100%; /* Width is 100% to fill the container */
  height: 80vh; /* Height is based on the viewport height */
  border: 0; /* Remove border */
  background-color: #96ab98;
  height: 100vh;
}

/* Media query for smaller devices */
@media (max-width: 768px) {
  .googleForm {
    height: 90vh; /* Increase height on smaller screens if needed */
  }
}

.Presentation img{
 max-width:100%;
 max-height:100%;
 margin: auto;
 display: block;
 margin-top: 30px;
}

.FundraisingInfoPresentation img{
 max-width:100%;
 max-height:100%;
 margin: auto;
 display: block;
 margin-top: 30px;

}

.eventHeader h3{
  color: red;
  text-align: center;
  font-size: 25px;
  margin-top: 50px;
  margin-left: 80px;
  margin-right: 80px;
}

.goals img {
 max-width:100%;
 max-height:100%;
 margin: auto;
 display: block;
 margin-top: 70px;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width:100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  align-items: flex-start; /* Align to the top */
  padding-top: 50px; /* Add some space at the top */
  overflow-y: auto;
}

.modal h5 {
  margin-top: 10px;
  padding-top: 20px;
  padding-bottom: 15px;
  color: #40820c;
  font-weight: 700;
}

.modal u {
  color: #40820c;
}

.modal-content {
  font-family: 'Barlow', sans-serif;
  background: white;
  padding: 20px;
  border-radius: 5px;
  position: relative;
  width: 90%;
  max-width: 700px; /* This will make the modal more square-shaped */
  box-shadow: 0 5px 15px rgba(0,0,0,0.3);
  margin: auto;
}

.close {
  position: absolute;
  top: -10px;
  right: 15px;
  border: none;
  background: transparent;
  font-size: 3em;
  cursor: pointer;
  color: #40820c;
}

.modal-buttons {
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
}

.modal-buttons button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #69b32e;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.modal-buttons button:hover {
  background-color: #40820c;
}

.modal b {
  color: #40820c;
}

@media (max-width: 600px) {
  .modal-content {
    width: 85%;
    overflow-y: auto
  }

  .modal-buttons button {
    width: 45%; /* Make buttons fill the modal width on smaller screens */
    padding: 10px;
    font-size: 0.8em; /* Smaller font size on mobile devices */
  }
}